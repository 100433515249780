import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";

const data = {
  loaders: {
    appLoad: `
    <div style="display:flex; height: 100vh; align-items:center; justify-content:center;">
      <div style="font-family:'sans-serif';">
        <img src="https://storeking.in/assets/assets/images/logo/logo_2.png" />
        <div style="text-align:center; font-size:12px; margin-top: 5px;">Loading, just a moment...</div>
      </div>
    </div>
    `,
  },
};

const routes = constructRoutes(microfrontendLayout, data);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();
